<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <!-- <CIcon name="cil-grid"/> -->
              <h4 class="float-left">Data Pekerjaan</h4>
              <select
                style="margin-left: 15px; width: 100px"
                @change="loadData"
                v-model="tahun"
                class="form-control-sm float-left form-control"
              >
                <option v-for="year in years" :value="year" v-bind:key="year">
                  {{ year }}
                </option>
              </select>
              <a
                href="/#/pekerjaan/add"
                class="float-right btn btn-success form-control-sm"
                >Tambah Pekerjaan</a
              >
            </slot>
          </CCardHeader>
          <CCardBody>
            <table class="table table-bordered" id="datatable">
              <thead>
                <tr>
                  <th rowspan=2>#</th>
                  <th rowspan=2>Nama Pekerjaan</th>
                  <th rowspan=2>Lokasi</th>
                  <th rowspan=2>Nilai Kontrak</th>
                  <th rowspan=2>Nama Penyedia Jasa</th>
                  <th rowspan=2>Konsultan Supervisi</th>
                  <th rowspan=2>PPTK</th>
                  <th colspan=2>Tim Pendukung</th>
                  <th rowspan=2>Edit</th>
                  <th rowspan=2>Detail</th>
                </tr>
                <tr>
                  <th>Pengawas 1</th>
                  <th>Pengawas 2</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for="(item, index) in products" :key="item.id">
              <td>{{index+1}}</td>
              <td>{{item.nama}}</td>
              <td>{{item.sub_kegiatan}}</td>
              <td>{{item.nama_pekerjaan}}</td>
              <td>{{ item.nilai_kontrak | rupiah }}</td>
            </tr>
             -->
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
 
<script>
let users = JSON.parse(localStorage.getItem('user'));
// let level = users.level;
import "jquery/dist/jquery.min.js";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import $ from "jquery";

export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  filters: {
    rupiah: function (value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    },
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2019 },
        (value, index) => new Date().getFullYear() - index
      );
    },
  },
  mounted() {
    this.loadData();
  },
  data: function () {
    return {
      products: [],
      user : JSON.parse(localStorage.getItem('user')),
      tahun: new Date().getFullYear(),
    };
  },
  methods: {
    loadData: function () {
      let username = this.user.id;
      console.log(this.user);
      let id_kontraktor = this.user.id_kontraktor;
      console.log("https://sippd.probowsolution.com/api/ajax_pekerjaan.php?action=table_data&k=" +
              id_kontraktor);
      if (this.tahun == null) {
        // your code here.
        // axios
        // .get("https://probowsolution.com/sippd/api/public/index.php/pekerjaan/2021?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
        // this.products = response.data;
        $.fn.dataTable.ext.errMode = 'none';
        $("#datatable").DataTable({
          processing: true,
          serverSide: true,
          pageLength: 50,
          ajax: {
            url:
              "https://sippd.probowsolution.com/api/ajax_pekerjaan.php?action=table_data&k=" +
              id_kontraktor,
            dataType: "json",
            type: "POST",
          },
          columns: [
            { data: "no" },
            { data: "nama_pekerjaan" },
            { data: "lokasi" },
            { data: "nilai_kontrak" },
            { data: "nama_kontraktor" },
            { data: "konsultan" },
            { data: "pptk" },
            { data: "pengawas1" },
            { data: "pengawas2" },
            { data: "edit" },
            { data: "detail" },
          ],
        });

        // })
      } else {
        
        //alert("https://sippd.probowsolution.com/api/ajax_pekerjaan.php?action=table_data&tahun="+this.tahun+"&k="+id_kontraktor);
        $.fn.dataTable.ext.errMode = 'none';
        $("#datatable").DataTable({
          processing: true,
          serverSide: true,
          pageLength: 50,
          ajax: {
            url:
              "https://sippd.probowsolution.com/api/ajax_pekerjaan.php?action=table_data&tahun="+this.tahun+"&k="+id_kontraktor,
            dataType: "json",
            type: "POST",
          },
          columns: [
            { data: "no" },
            { data: "nama_pekerjaan" },
            { data: "lokasi" },
            { data: "nilai_kontrak" },
            { data: "nama_kontraktor" },
            { data: "konsultan" },
            { data: "pptk" },
            { data: "pengawas1" },
            { data: "pengawas2" },
            { data: "edit" },
            { data: "detail" },
          ],
        });

        // })
      }
    },
  },
};
</script>